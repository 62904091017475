<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="procurementrules">
        <p class="anaproduct_tit">采购规则</p>
        <p class="anaproduct_xuanze">
            采购产品选择：
            &nbsp;&nbsp;&nbsp;&nbsp;
            <el-tooltip  effect="dark" content="只能选择低于最小库存的产品进行采购" placement="top-start">
                <span :class="0==caigou_chanpin_xuanze?'bei':''">
                    <img :src="0==caigou_chanpin_xuanze?require('../../../assets/me/xuan_zi.png'):require('../../../assets/ccc_quan.png')">
                    低于最小库存
                </span>
            </el-tooltip>
            <span :class="1==caigou_chanpin_xuanze?'bei':''">
                <img :src="1==caigou_chanpin_xuanze?require('../../../assets/me/xuan_zi.png'):require('../../../assets/ccc_quan.png')">
                无
            </span>
        </p>
        <p class="anaproduct_xuanze">
            采购数量限制：
            &nbsp;&nbsp;&nbsp;&nbsp;
            <el-tooltip  effect="dark" content="每种产品采购后的预计库存不能超过设置好的最大库存" placement="bottom-start">
                <span :class="0==caigou_shuliang_xuanze?'bei':''">
                    <img :src="0==caigou_shuliang_xuanze?require('../../../assets/me/xuan_zi.png'):require('../../../assets/ccc_quan.png')">
                    采购数量区间
                </span>
            </el-tooltip>
            <span :class="1==caigou_shuliang_xuanze?'bei':''">
                <img :src="1==caigou_shuliang_xuanze?require('../../../assets/me/xuan_zi.png'):require('../../../assets/ccc_quan.png')">
                无
            </span>
        </p>
    </div>
</template>

<script>
import { query_ent_site_tree, query_rule_stock_qty_list, query_rule_purchase_detail, query_prod_tree_sale_rule, insert_rule_stock, insert_rule_stock_qty, insert_rule_purchase } from '../../../api/api'
export default {
  name: 'procurementrules',
  data () {
    return {
      caigou_shuliang_list: [ // 采购数量限制
        {
          name: '采购数量区间',
          id: '0'
        },
        {
          name: '无',
          id: '1'
        }
      ],
      caigou_kucun_list: [ // 采购单库存计算规则
        {
          name: '按照已审核的出库入库单计算库存',
          id: '0'
        },
        {
          name: '按照已审核加未审核的出库入库单计算库存',
          id: '1'
        }
      ],
      caigou_qujian_list: [ // 产品库存区间设置
        {
          name: '仓库',
          id: '0'
        },
        {
          name: '产品名称',
          id: '1'
        }
      ],
      caigou_chanpin_xuanze: '1', // 采购产品选择
      caigou_shuliang_xuanze: '1', // 采购数量限制选择
      caigou_kucun_xuanze: '0', // 采购单库存计算规则
      caigou_qujian_xuanze: '0', // 产品库存区间设置选择
      biaotou: [],
      neirong_list: [],
      chushi_ziduan: ''
    }
  },
  mounted () {
  },
  components: { },
  created () {
    this.jichu()
  },
  watch: {
    caigou_qujian_xuanze () {
      this.qiehuan_biaotou(this.caigou_qujian_xuanze)
      if (this.chushi_ziduan == this.caigou_qujian_xuanze) {
        this.qiehuan_neirong(this.caigou_qujian_xuanze)
      } else {
        const list = [{
          label: this.caigou_qujian_xuanze == 0 ? '增加仓库' : '增加产品',
          min: '',
          max: '',
          caozuo: '',
          id: 'zengjia'
        }]
        this.neirong_list = list
      }
    }
  },
  methods: {
    jichu () {
      query_rule_purchase_detail({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.caigou_chanpin_xuanze = date != null && date.prod_select_type != undefined ? (date.prod_select_type == 1 ? '0' : '1') : '0'
          this.caigou_shuliang_xuanze = date != null && date.qty_limit_type != undefined ? (date.qty_limit_type == 1 ? '0' : '1') : '0'
        } else if (res.data.code == 500) {}
      })
    },
    qiehuan_biaotou (a) {
      let biaotou = []
      if (a == 0) {
        biaotou = [
          {
            name: '仓库',
            con: 'label'
          },
          {
            name: '最小库存',
            con: 'min'
          },
          {
            name: '最大库存',
            con: 'max'
          },
          {
            name: '操作',
            con: 'caozuo'
          }
        ]
      } else {
        biaotou = [
          {
            name: '产品',
            con: 'label'
          },
          {
            name: '最小库存',
            con: 'min'
          },
          {
            name: '最大库存',
            con: 'max'
          },
          {
            name: '操作',
            con: 'caozuo'
          }
        ]
      }
      this.biaotou = biaotou
    },
    qiehuan_neirong (a) {
      query_rule_stock_qty_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          let list = []
          if (date) {
            date.forEach(item => {
              this.$set(item, 'label', this.caigou_kucun_xuanze == 0 ? item.site_name : item.prod_name)
              this.$set(item, 'id', this.caigou_kucun_xuanze == 0 ? item.site_id : item.prod_id)
              this.$set(item, 'min', item.stock_num_min != undefined && item.stock_num_min.length != 0 ? item.stock_num_min : '')
              this.$set(item, 'max', item.stock_num_max != undefined && item.stock_num_max.length != 0 ? item.stock_num_max : '')
              this.$set(item, 'caozuo', '')
            })
            list = date
          }
          list.push(
            {
              label: this.caigou_qujian_xuanze == 0 ? '增加仓库' : '增加产品',
              min: '',
              max: '',
              caozuo: '',
              id: 'zengjia'
            }
          )
          this.neirong_list = list
        } else if (res.data.code == 500) {}
      })
    },
    // 表头颜色
    headerCellStyle () { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    chuanzhi (data) {
      console.log(data)
      const zhi = [] // 选择项里  与  当前列表公共的数据
      const yi_cunzai = [] // 已存在
      data.forEach(item => {
        if (item.children == undefined) {
          if (!item.yiyou) {
            this.$set(item, 'min', '')
            this.$set(item, 'max', '')
            this.$set(item, 'caozuo', '')
            zhi.push(item)
          } else {
            yi_cunzai.push(item)
          }
        }
      })
      this.neirong_list.forEach(item => {
        yi_cunzai.forEach(ite => {
          if (item.id == ite.id) {
            zhi.push(item)
          }
        })
      })
      zhi.push(
        {
          label: this.caigou_qujian_xuanze == 0 ? '增加仓库' : '增加产品',
          min: '',
          max: '',
          caozuo: '',
          id: 'zengjia'
        }
      )
      console.log(zhi)

      this.neirong_list = zhi
      console.log(this.neirong_list)
    },
    dianji_zengjia () {
      if (this.caigou_qujian_xuanze == 0) {
        query_ent_site_tree({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.$refs.cangku.jichu(date, '仓库选择', this.neirong_list, '多选', '有关联')
          } else if (res.data.code == 500) {}
        })
      } else {
        query_prod_tree_sale_rule({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.$refs.cangku.jichu(date, '产品选择', this.neirong_list, '多选', '有关联')
          } else if (res.data.code == 500) {}
        })
      }
      // this.$refs.cangku.jichu([],'我来测试')
    },
    dianji_baocun () {
      if (this.neirong_list.length > 1) {
        const list = JSON.parse(JSON.stringify(this.neirong_list))
        list.splice(list.length - 1, 1)
        const zhi = []
        if (this.caigou_kucun_xuanze == 0) {
          list.forEach(item => {
            zhi.push({
              site_no: item.site_no,
              site_id: item.id,
              stock_num_min: item.min,
              stock_num_max: item.max
            })
          })
        } else {
          list.forEach(item => {
            zhi.push({
              prod_id: item.id,
              prod_code: item.prod_code,
              stock_num_min: item.min,
              stock_num_max: item.max
            })
          })
        }
        insert_rule_purchase({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            prod_select_type: this.caigou_chanpin_xuanze == 1 ? '0' : '1', // 采购产品选择
            qty_limit_type: this.caigou_shuliang_xuanze == 1 ? '0' : '1'// 采购数量限制选择
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            insert_rule_stock({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                stock_type: this.caigou_qujian_xuanze == 0 ? '1' : '2', // 产品库存区间设置   1仓库  2产品
                purchase_stock_cal_type: this.caigou_kucun_xuanze == 0 ? '1' : '2'// 采购单库存计算  1按照以审核的出库入库单计算库存  2按照已审核家未审核的出库入库单计算库存
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 200) {
                insert_rule_stock_qty({
                  data: {
                    ent_id: this.$ent_id(),
                    user_id: this.$jichuxinxi().user_id,
                    list: zhi,
                    stock_type: this.caigou_qujian_xuanze == 0 ? '1' : '2'// 产品库存区间设置   1仓库  2产品
                  }
                }).then(rzs => {
                  console.log(rzs)
                  if (rzs.data.code == 200) {
                    this.$message({
                      message: '保存成功',
                      type: 'success'
                    })
                  } else if (rzs.data.code == 500) {
                    this.$message({
                      message: '保存失败',
                      type: 'warning'
                    })
                  }
                })
              } else if (ras.data.code == 500) {
                this.$message({
                  message: '保存失败',
                  type: 'warning'
                })
              }
            })
          } else if (res.data.code == 500) {
            this.$message({
              message: '保存失败',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整信息',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.procurementrules{
    padding: 0.1rem;
    .anaproduct_tit{
        font-size: 0.14rem;
        font-weight: 600;
        padding: 0.1rem 0;
    }
    .anaproduct_xuanze{
        display: flex;
        align-items: center;
        margin: 0.1rem 0;
        span{
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                width:0.16rem;
                height:0.16rem;
                margin: 0 0.1rem;
            }
        }
        .bei{
            color:rgb(154, 132, 219)
        }
    }
    .anaproduct_kucun_xuanze{
        display: flex;
        margin: 0.1rem 0;
        li{
            display: flex;
            align-items: center;
            margin-bottom: 0.08rem;
            cursor: pointer;
            img{
                width:0.16rem;
                height:0.16rem;
                margin: 0 0.1rem;
            }
        }
        .bei{
            color:rgb(154, 132, 219)
        }
    }
    .anaproduct_qujian_xuanze{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.1rem 0;
        span{
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                width:0.16rem;
                height:0.16rem;
                margin: 0 0.1rem;
            }
        }
        .bei{
            color:rgb(154, 132, 219)
        }
    }
    .anaproduct_biaoge{
        .tianjia_hang{
            height:0.32rem;
            width:0.8rem;
            line-height: 0.32rem;
            text-align: center;
            color:#fff;
            background:#9a86db;
            display: block;
            border-radius: 0.03rem;
            margin:0 auto;
            cursor: pointer;
        }
        .shanchu_hang{
            height:0.3rem;
            width: 0.3rem;
            border-radius: 50%;
            border:1px solid #ccc;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            i{
                text-align: center;
                line-height: 0.6rem;
                font-size: 0.2rem;
                color:#ccc;
            }
            &:hover{
                border:1px solid #9a86db;
                i{
                    color:#9a86db;
                }
            }
        }
    }
    .btn_sure{
        height:0.32rem;
        width:0.8rem;
        line-height: 0.32rem;
        text-align: center;
        color:#fff;
        background:#9a86db;
        border-radius: 0.03rem;
        margin:0.3rem auto;
        cursor: pointer;
    }
}
</style>
